import React, { useState } from "react";
import { Wheel } from "react-custom-roulette";
import { Stack, Button } from "react-bootstrap";
// @ts-ignore
import { confirm } from "react-bootstrap-confirmation";
import Web3 from "web3";
import toast from "react-hot-toast";

import Layout from "../layout";
import Header from "../components/Header";
// @ts-ignore
import WheelAudio from "../assets/wheel.wav";
import WheelIndicator from "../assets/img/wheel-indicator.png";
import { WRITE_CREDIT_CONTRACT } from "../configs/smart_contracts";
import {
  setPlaySpinFortuneApi,
  setFortuneSpinScoreApi,
  getUserApi,
} from "../apis/coupon";
import { store } from "../store";
import { customToastStyle } from "../configs/constants";
import WinningCoupons from "../components/WinningCoupons";
import SelectGameFortune from "../components/SelectGameFortune";

const data = [
  { option: "4", style: { backgroundColor: "white" } },
  { option: "1", style: { backgroundColor: "gray" } },
  { option: "2", style: { backgroundColor: "white" } },
  { option: "3", style: { backgroundColor: "gray" } },
  { option: "0", style: { backgroundColor: "gray" } },
];

function FortuneSpin(props: any) {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [prizeCoupons, setPrizeCoupons] = useState([]);
  const [showCoupons, setShowCoupons] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSelectGame, setShowSelectGame] = useState(true);
  const [sportType, setSportType] = useState("");

  const [account] = store.useState("account");
  const [, setUserInfo] = store.useState("userInfo");

  const handleSpinClick = async () => {
    try {
      if (mustSpin || loading) {
        return;
      }
      setLoading(true);

      const res = await confirm("You will pay 1 credit to play Fortune Spin");

      if (res) {
        const writeCreditContract = await WRITE_CREDIT_CONTRACT();
        await writeCreditContract.methods
          .burnCredit(Web3.utils.toWei("1", "ether"))
          .send({ from: account });

        const res = await setPlaySpinFortuneApi(account);
        if (!res) {
          return toast("Error to play Fortune Spin", customToastStyle);
        }

        const newPrizeNumber = Math.floor(Math.random() * data.length);

        const _prizeCoupons = await setFortuneSpinScoreApi({
          score: newPrizeNumber,
          wallet: account,
          sportType,
        });
        const _userInfo = await getUserApi(account);

        let _newPrizeNumber = newPrizeNumber;
        if (newPrizeNumber === 0) {
          _newPrizeNumber = 4;
        }
        if (newPrizeNumber === 4) {
          _newPrizeNumber = 0;
        }
        setPrizeNumber(_newPrizeNumber);
        setMustSpin(true);

        setPrizeCoupons(_prizeCoupons);
        setUserInfo(_userInfo);

        setTimeout(() => {
          new Audio(WheelAudio).play();
        }, 800);
      }
    } catch (err: any) {
      toast(err.message, customToastStyle);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <WinningCoupons winningCoupons={prizeCoupons} show={showCoupons} />
      <SelectGameFortune
        show={showSelectGame}
        handleSelect={(val: string) => setSportType(val)}
        handleNext={() => setShowSelectGame(false)}
      />
      <div className="page-container">
        <Header />
        <div style={{ zIndex: 2 }}>
          <Stack
            style={{
              background: "#291e655e",
              alignItems: "center",
              borderRadius: 20,
            }}
            className="p-4 mt-5"
          >
            <h1>Free Credits</h1>
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              backgroundColors={["#fff"]}
              data={data}
              pointerProps={{
                src: WheelIndicator,
                style: { rotate: "45deg", width: 100 },
              }}
              radiusLineColor={"#b884f2"}
              radiusLineWidth={3}
              outerBorderColor={"#9b6ace"}
              innerBorderColor="#b884f2"
              innerBorderWidth={15}
              onStopSpinning={() => {
                setMustSpin(false);
                setShowCoupons(true);
              }}
            />
            <Button className="action-button mt-5" onClick={handleSpinClick}>
              {loading ? "Loading..." : "Spin"}
            </Button>
          </Stack>
        </div>
      </div>
    </Layout>
  );
}

export default FortuneSpin;
