import Layout from "../layout";
import Header from "../components/Header";
import TxsTable from "../components/TxsTable";

function MyTxs(props: any) {
   return (
    <Layout>
      <div className="page-container">
        <div style={{ zIndex: 2 }} className="w-100">
          <Header />
          <TxsTable />
        </div>
      </div>
    </Layout>
  );
}

export default MyTxs;
