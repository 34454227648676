import React, { useEffect, useState } from "react";
import { Container, Form, Image, Button, Stack } from "react-bootstrap";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import toast from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";

import Layout from "../layout";
import Header from "../components/Header";
import DiamondImg from "../assets/img/diamond.png";

import { getDiamondPriceApi, putDiamondSwapRequestApi } from "../apis/coupon";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../configs/constants";

import { store } from "../store";

export default function SwapDiamond() {
  const nav = useNavigate();

  const [amount, setAmount] = useState("");
  const [usd, setUsd] = useState("");
  const [diamondPrice, setDiamondPrice] = useState("");
  const [order, setOrder] = useState(true);
  const [loading, setLoading] = useState(false);

  const [account] = store.useState("account");
  const [userInfo] = store.useState("userInfo");
  const myDiamond = (userInfo as any)?.diamond || 0;

  useEffect(() => {
    if (order) {
      setUsd((parseFloat(amount) / parseFloat(diamondPrice) || 0) as any);
    }
  }, [amount]);

  useEffect(() => {
    if (!order) {
      setAmount((parseFloat(usd) * parseFloat(diamondPrice) || 0) as any);
    }
  }, [usd]);

  useEffect(() => {
    (async () => {
      setDiamondPrice(await getDiamondPriceApi());
    })();
  }, []);

  const requestSwapDiamondHandler = async () => {
    if (!(parseFloat(amount) > 0)) {
      return toast("Please input valid amount", customToastStyle);
    }
    if (parseFloat(myDiamond) < parseFloat(amount)) {
      return toast("Insufficient diamond balance", customToastStyle);
    }

    // @author puffer is working for now.
    try {
      setLoading(true);

      const res = await putDiamondSwapRequestApi({
        wallet: account,
        diamondAmount: amount + "",
        usdtAmount: usd + "",
        totalDiamondBalance: myDiamond + "",
      });
      if (res) {
        toast("Requested to swap diamond to usdt", customToastSuccessStyle);
        setAmount("0");
        setUsd("0");
      } else {
        toast("Failed swap request", customToastStyle);
      }

      nav("/my-txs");
    } catch (e: any) {
      toast("Error: " + e.message, customToastStyle);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="page-container">
        <div style={{ zIndex: 2 }} className="w-100">
          <Header />
          <Container style={{ marginTop: 50 }}>
            <Form className="w-50">
              <Stack
                direction="horizontal"
                style={{ alignItems: "center", display: "flex" }}
              >
                <h2>Request to Swap Diamonds To USDT</h2>
              </Stack>
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>
                  Diamonds Amount ($1 = {diamondPrice} Diamonds)
                </Form.Label>
                <Form.Control
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <div
                  className="d-flex justify-content-center mt-4 "
                  style={{ cursor: "pointer" }}
                  onClick={() => setOrder(!order)}
                >
                  <FaArrowDown />
                  <FaArrowUp />
                </div>
                <Form.Label>USDT</Form.Label>
                <Form.Control
                  type="text"
                  value={usd}
                  disabled={true}
                  style={{ opacity: 0.9 }}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-3">
                <Button
                  onClick={requestSwapDiamondHandler}
                  disabled={loading}
                  style={{ minWidth: 160 }}
                >
                  {loading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    <>
                      <Image src={DiamondImg} width={30} className="mx-2" />
                      Submit
                    </>
                  )}
                </Button>
              </Form.Group>
            </Form>
          </Container>
        </div>
      </div>
    </Layout>
  );
}
