import { useMemo, useState } from "react";
import { store } from "../store";
import toast from "react-hot-toast";
import { Button, Image, Nav } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { FaBars, FaTimes } from "react-icons/fa";

import useResponsive from "../hooks/useResponsive";

import { customToastSuccessStyle } from "../configs/constants";
import { getShortAddress, getLocaleString } from "../utils";

import PolygonImg from "../assets/img/polygon.svg";
import CoinImg from "../assets/img/coin.png";
import DiamondImg from "../assets/img/diamond.png";

export default function Header() {
  const nav = useNavigate();

  const location = useLocation();

  const [balance] = store.useState("balance");
  const [account, setAccount] = store.useState("account");
  const [userInfo] = store.useState("userInfo");

  const [showMobile, setShowMobile] = useState(false);

  const isMedium = !useResponsive(930);
  const isSmall = useResponsive(650);

  const isAdmin = useMemo(() => {
    return (
      process.env.REACT_APP_OWNER === account ||
      account === "0xdB2981154407E050562fEDbfd039D216050002E3" ||
      account === "0xcD889634eb2495CAB8816eA853b6838f3921165d"
    );
  }, [account]);

  const disconnectWalletHandler = async () => {
    setAccount("");
    nav("/");
    return toast("Disconnected success", customToastSuccessStyle);
  };

  return (
    <div className="w-100" style={{ zIndex: 100 }}>
      <div className="header">
        <div className="d-flex">
          {!isSmall && (
            <Nav variant="tabs" defaultActiveKey={location.pathname}>
              {isAdmin && (
                <Nav.Item>
                  <Nav.Link
                    eventKey="/admin/games"
                    onClick={() => nav("/admin/games")}
                  >
                    Admin
                  </Nav.Link>
                </Nav.Item>
              )}
              {account &&
                ((
                  <Nav.Item>
                    <Nav.Link
                      eventKey="/dashboard"
                      onClick={() => nav("/dashboard")}
                    >
                      Dashboard
                    </Nav.Link>
                  </Nav.Item>
                ) as any)}
              {/* <Nav.Item>
                <Nav.Link
                  eventKey="/tower_master"
                  onClick={() => nav("/tower_master")}
                >
                  Tower Master
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="/spin" onClick={() => nav("/spin")}>
                  Credits Wheel
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="/buy/credits"
                  onClick={() => nav("/buy/credits")}
                >
                  Buy Credits
                </Nav.Link>
              </Nav.Item> */}
              {/* <Nav.Item>
                <Nav.Link eventKey="/games" onClick={() => nav("/games")}>
                  Sports Bets
                </Nav.Link>
              </Nav.Item> */}
              {/* <Nav.Item>
                <Nav.Link
                  eventKey="/buy/coupons"
                  onClick={() => nav("/buy/coupons")}
                >
                  Buy Coupons
                </Nav.Link>
              </Nav.Item> */}
              {/* <Nav.Item>
                <Nav.Link
                  eventKey="/leader_board"
                  // onClick={() => nav("/leader_board")}
                >
                  Leader Board
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
          )}
        </div>
        {isSmall ? null : isMedium ? (
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center balance">
              <Image src={CoinImg} width={40} className="m-1" />{" "}
              <b>{getLocaleString((userInfo as any)?.credit) || 0}</b>&nbsp;
              Credit
            </div>
            <div
              className="d-flex align-items-center balance"
              style={{ marginLeft: 5 }}
            >
              <Image src={DiamondImg} width={30} className="m-1" />{" "}
              <b>{getLocaleString((userInfo as any)?.diamond) || 0}</b>
              &nbsp; Diamond
            </div>
            <div
              className="d-flex align-items-center balance"
              style={{ marginLeft: 5 }}
            >
              <Image src={PolygonImg} width={25} className="m-1" />{" "}
              {balance?.toString()}&nbsp; MATIC
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="default" style={{ color: "white" }}>
                {getShortAddress(account)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => nav("/my-coupons")}>
                  View My Coupons
                </Dropdown.Item>
                <Dropdown.Item onClick={() => nav("/my-txs")}>
                  View My Transactions
                </Dropdown.Item>
                <Dropdown.Item onClick={() => nav("/my-prediction")}>
                  View My Predictions
                </Dropdown.Item>
                <Dropdown.Item onClick={disconnectWalletHandler}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <Dropdown>
            <Dropdown.Toggle variant="default" style={{ color: "white" }}>
              {getShortAddress(account)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <Image src={CoinImg} width={20} className="m-1" />{" "}
                {getLocaleString((userInfo as any)?.credit) || 0} Credit
              </Dropdown.Item>
              <Dropdown.Item>
                <Image src={DiamondImg} width={18} className="m-1" />{" "}
                {getLocaleString((userInfo as any)?.diamond) || 0} Diamond
              </Dropdown.Item>
              <Dropdown.Item>
                <Image src={PolygonImg} width={15} className="m-1" />{" "}
                {balance?.toString()} MATIC
              </Dropdown.Item>
              <Dropdown.Item onClick={() => nav("/my-coupons")}>
                View My Coupons
              </Dropdown.Item>
              <Dropdown.Item onClick={() => nav("/my-txs")}>
                View My Transactions
              </Dropdown.Item>
              <Dropdown.Item onClick={() => nav("/my-prediction")}>
                View My Predictions
              </Dropdown.Item>
              <Dropdown.Item onClick={disconnectWalletHandler}>
                Lot out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {isSmall && (
          <Button
            variant="default"
            style={{ color: "white" }}
            onClick={() => setShowMobile(!showMobile)}
          >
            {showMobile ? <FaTimes size={28} /> : <FaBars size={26} />}
          </Button>
        )}
      </div>
      {isSmall && showMobile && (
        <Nav
          variant="pills"
          className="flex-column"
          style={{ backgroundColor: "#160b53" }}
          defaultActiveKey={location.pathname}
        >
          {isAdmin && (
            <Nav.Item>
              <Nav.Link
                eventKey="/admin/games"
                onClick={() => nav("/admin/games")}
              >
                Admin
              </Nav.Link>
            </Nav.Item>
          )}
          {/* <Nav.Item>
            <Nav.Link
              eventKey="/tower_master"
              onClick={() => nav("/tower_master")}
            >
              Tower Master
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="/spin" onClick={() => nav("/spin")}>
              Credits Wheel
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="/buy/credits"
              onClick={() => nav("/buy/credits")}
            >
              Buy Credits
            </Nav.Link>
          </Nav.Item> */}
          {/* <Nav.Item>
            <Nav.Link eventKey="/games" onClick={() => nav("/games")}>
              Sports Bets
            </Nav.Link>
          </Nav.Item> */}
          {/* <Nav.Item>
            <Nav.Link
              eventKey="/buy/coupons"
              onClick={() => nav("/buy/coupons")}
            >
              Buy Coupons
            </Nav.Link>
          </Nav.Item> */}
          {/* <Nav.Item>
            <Nav.Link
              eventKey="/leader_board"
              // onClick={() => nav("/leader_board")}
            >
              Leader Board
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
      )}
    </div>
  );
}
