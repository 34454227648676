import React, { useMemo, useState, useRef, useEffect } from "react";
import { Container, Stack, Button, Row } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
// @ts-ignore
import { createTimeModel, useTimeModel } from "react-compound-timer";
// @ts-ignore
import { confirm } from "react-bootstrap-confirmation";
import { useNavigate } from "react-router-dom";

import Layout from "../layout";
import Header from "../components/Header";
import ScoreCell from "../components/TowerMaster/ScoreCell";
import PrizeCell from "../components/TowerMaster/PrizeCell";
import Cell from "../components/TowerMaster/Cell";
import GameOver from "../components/TowerMaster/GameOver";
import SelectGame from "../components/SelectGame";
import {
  getTimeLimitTowerMasterApi,
  setTowerMasterScoreApi,
} from "../apis/coupon";
import { store } from "../store";

// @ts-ignore
import MovingWav from "../assets/game/moving.wav";
// @ts-ignore
import MissionFailWav from "../assets/game/mission-fail.wav";
// @ts-ignore
import GameOverWav from "../assets/game/game-over.wav";
// @ts-ignore
import GameReachTopWav from "../assets/game/reach-top.wav";
// @ts-ignore
import GameWinWav from "../assets/game/win.wav";

const GAME_WIDTH = 7;
const GAME_HEIGHT = 14;
const GAME_INTERVAL = [
  300, 300, 300, 300, 300, 300, 300, 300, 300, 210, 200, 190, 180, 170,
];
const GAME_MASK_WIDTH = [3, 3, 3, 2, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1];
enum GameDirection {
  LEFT_TO_RIGHT,
  RIGHT_TO_LEFT,
}

enum GameStatus {
  PLAYING,
  FAIL,
  SUCCESS,
}

let stopwatch = createTimeModel();

export default function TowerMaster() {
  const nav = useNavigate();
  const { value } = useTimeModel(stopwatch);

  const [tower, setTower] = useState(
    Array.from({ length: GAME_HEIGHT }, () => Array(GAME_WIDTH).fill(false))
  );
  const [cursor, setCursor] = useState({ x: 0, y: 0 });
  const [isPlaying, setIsPlaying] = useState(false);
  const [dbTimeLimit, setDbTimeLimit] = useState("15");
  const [showSelectGame, setShowSelectGame] = useState(true);
  const [startGame, setStartGame] = useState(false);
  const [sportType, setSportType] = useState("");
  const [winningCoupons, setWinningCoupons] = useState([]);
  const [started, setStarted] = useState(false);

  const [account] = store.useState("account");

  const mask = useRef(3);
  const gameDirection = useRef(GameDirection.LEFT_TO_RIGHT);
  const intervalHandler: any = useRef(null);
  const isFinished = useRef(false);

  useEffect(() => {
    (async () => {
      setDbTimeLimit(await getTimeLimitTowerMasterApi());
    })();
  }, []);

  const saveScore = async () => {
    console.log(cursor.y, value.s, value.m);
    const delay = value.m * 60 + value.s;
    if (account) {
      // save score
      const coupons = await setTowerMasterScoreApi({
        level: cursor.y,
        delay: delay,
        sportType: sportType,
        wallet: account,
      });
      setWinningCoupons(coupons);
    }
  };

  const gameStatus = useMemo(() => {
    if (isFinished.current && !isPlaying) {
      saveScore();
      if (cursor.y < GAME_HEIGHT) {
        return GameStatus.FAIL;
      }
      return GameStatus.SUCCESS;
    }
    return GameStatus.PLAYING;
  }, [isPlaying, cursor.y]);

  const nextRound = () => {
    setCursor((prev) => {
      const prevCursor = { ...prev };

      gameDirection.current =
        gameDirection.current === GameDirection.LEFT_TO_RIGHT
          ? GameDirection.RIGHT_TO_LEFT
          : GameDirection.LEFT_TO_RIGHT;

      if (gameDirection.current === GameDirection.LEFT_TO_RIGHT) {
        prevCursor.x = 0;
      } else {
        prevCursor.x = GAME_WIDTH - 1;
      }

      prevCursor.y = prevCursor.y + 1;

      return prevCursor;
    });
  };

  const nextPoint = () => {
    setCursor((prev) => {
      const prevCursor = { ...prev };
      if (gameDirection.current === GameDirection.LEFT_TO_RIGHT) {
        prevCursor.x = prevCursor.x + 1;
      } else {
        prevCursor.x = prevCursor.x - 1;
      }
      // cursory is rendering variable
      const cursorY = cursor.y + 1;
      const activeMask = Math.min(mask.current, GAME_MASK_WIDTH[cursorY]);
      if (
        prevCursor.x === GAME_WIDTH - activeMask &&
        gameDirection.current === GameDirection.LEFT_TO_RIGHT
      ) {
        gameDirection.current = GameDirection.RIGHT_TO_LEFT;
      } else if (
        prevCursor.x === 0 &&
        gameDirection.current === GameDirection.RIGHT_TO_LEFT
      ) {
        gameDirection.current = GameDirection.LEFT_TO_RIGHT;
      }
      return prevCursor;
    });
  };

  const getFirstActiveX = (y: number) => {
    let j = 0;
    while (!tower[y][j]) {
      j++;
      if (j === GAME_WIDTH) {
        j = -1;
        break;
      }
    }
    return j;
  };

  const getLastActiveX = (y: number) => {
    let j = GAME_WIDTH - 1;
    while (!tower[y][j]) {
      j--;
      if (j === 0) {
        j = -1;
        break;
      }
    }
    return j;
  };

  const isBelongPrevMask = ({
    prevFirstActiveX,
    prevLastActiveX,
    currentIndex,
  }: {
    prevFirstActiveX: number;
    prevLastActiveX: number;
    currentIndex: number;
  }) => {
    return prevFirstActiveX <= currentIndex && currentIndex <= prevLastActiveX;
  };

  const calculateBlock = () => {
    const prevTower = [...tower];
    let scoreCount = 0;
    const activeMask = Math.min(mask.current, GAME_MASK_WIDTH[cursor.y]);
    for (let i = 0; i < activeMask; i++) {
      const maskX = cursor.x + i;
      if (cursor.y === 0) {
        // first row
        prevTower[cursor.y][maskX] = true;
        scoreCount++;
      } else {
        const prevFirstActiveX = getFirstActiveX(cursor.y - 1);
        const prevLastActiveX = Math.min(
          getLastActiveX(cursor.y - 1),
          GAME_WIDTH
        );
        const isBelong = isBelongPrevMask({
          prevFirstActiveX,
          prevLastActiveX,
          currentIndex: maskX,
        });

        prevTower[cursor.y][maskX] = isBelong; // filter prev mask
        if (isBelong) {
          scoreCount++;
        }
      }
    }

    if (mask.current < scoreCount) {
      new Audio(MissionFailWav).play();
    }
    mask.current = scoreCount;

    setTower(prevTower);

    if (scoreCount === 0) {
      isFinished.current = true;
      clearInterval(intervalHandler.current);
    }
  };

  const isPlayingCell = (roxX: number, rowY: number) => {
    const activeMask = Math.min(mask.current, GAME_MASK_WIDTH[cursor.y]);
    if (rowY === cursor.y && isPlaying && !isFinished.current) {
      return cursor.x <= roxX && roxX <= cursor.x + activeMask - 1;
    }
    return false;
  };

  const play = async () => {
    intervalHandler.current = setInterval(() => {
      nextPoint();
    }, GAME_INTERVAL[cursor.y]);
  };

  const playHandler = async () => {
    if (!startGame) {
      // check while select game option
      return;
    }
    if (gameStatus !== GameStatus.PLAYING) {
      return;
    }
    if (!isPlaying) {
      // start
      stopwatch = createTimeModel();

      setStarted(true);
      setIsPlaying(true);
      return play();
    }

    calculateBlock();
    clearInterval(intervalHandler.current);

    if (isFinished.current) {
      // end game
      setIsPlaying(false);
    } else {
      // continue playing
      nextRound();
      play();
    }
  };

  const playAgainHandler = async () => {
    mask.current = 3;
    gameDirection.current = GameDirection.LEFT_TO_RIGHT;
    intervalHandler.current = null;
    isFinished.current = false;

    setTower(
      Array.from({ length: GAME_HEIGHT }, () => Array(GAME_WIDTH).fill(false))
    );
    setCursor({ x: 0, y: 0 });
    setIsPlaying(false);
    setShowSelectGame(true);
    setStartGame(false);
    setSportType("");
    setWinningCoupons([]);
  };

  const exitGameHandler = async () => {
    const res = await confirm(`Do you want to finish game?`);
    if (res) {
      nav("/dashboard");
    }
  };

  return (
    <Layout>
      <SelectGame
        show={showSelectGame}
        onHide={() => setShowSelectGame(false)}
        handleSelect={(val: string) => setSportType(val)}
        handleNext={() => setShowSelectGame(false)}
        handleOpen={() => setShowSelectGame(true)}
        handleGameStart={() => setStartGame(true)}
      />
      <div className="page-container">
        <Header />
        <Stack
          className="p-4 mt-2 d-flex align-items-center"
          style={{ zIndex: 2, position: "relative" }}
        >
          <AiOutlineCloseCircle
            fontSize={36}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: 60,
              top: 40,
            }}
            onClick={exitGameHandler}
          />
          <h1>Tower Master </h1>
          <div style={{ color: "#27ff00", textAlign: "center" }}>
            Stack the blocks to the top and win prediction coupons on different
            levels + extra one if top is reached in {dbTimeLimit} seconds
            <br />
            Hit start to commence
          </div>
          {started && (
            <div>
              {value.m} minutes {value.s} seconds
            </div>
          )}
          {isPlaying ? <audio src={MovingWav} autoPlay loop /> : null}
          <GameOver
            show={gameStatus !== GameStatus.PLAYING}
            winningCoupons={winningCoupons}
            playAgain={playAgainHandler}
          />
          {gameStatus === GameStatus.FAIL && cursor.y < 6 ? (
            <audio src={GameOverWav} autoPlay />
          ) : null}
          {gameStatus === GameStatus.FAIL && cursor.y >= 6 ? (
            <audio src={GameReachTopWav} autoPlay />
          ) : null}

          {gameStatus === GameStatus.SUCCESS ? (
            <audio src={GameWinWav} autoPlay />
          ) : null}

          <Container className="tower-master" style={{ marginTop: 40 }}>
            {tower.map((row, rowY) => {
              return (
                <Row
                  key={uuidv4()}
                  className="d-flex align-items-center justify-content-center"
                >
                  {
                    (
                      <PrizeCell
                        key={uuidv4()}
                        prize={rowY + 1}
                        delay={value.m * 60 + value.s}
                      />
                    ) as any
                  }
                  <ScoreCell key={uuidv4()} score={rowY + 1} />
                  {row.map((cell, rowX) => {
                    return (
                      <Cell
                        status={cell || isPlayingCell(rowX, rowY)}
                        key={uuidv4()}
                      />
                    );
                  })}
                  <ScoreCell key={uuidv4()} score={rowY + 1} />
                  {
                    (
                      <PrizeCell
                        key={uuidv4()}
                        prize={rowY + 1}
                        delay={value.m * 60 + value.s}
                      />
                    ) as any
                  }
                </Row>
              );
            })}
          </Container>
          <Container className="d-flex align-items-center justify-content-center mt-4">
            <Button
              variant="primary"
              size="lg"
              className="w-50 mx-3"
              style={{ maxWidth: 200, backgroundColor: "#160b53" }}
              onClick={playHandler}
            >
              {gameStatus === GameStatus.FAIL && "Game Over"}
              {gameStatus !== GameStatus.FAIL && isPlaying && "Stop"}
              {gameStatus !== GameStatus.FAIL && !isPlaying && "Play"}
            </Button>
          </Container>
        </Stack>
      </div>
    </Layout>
  );
}
