import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";

import { getCouponsApi, deleteCouponApi } from "../../apis/coupon";
import { capitalizeFirstLetter } from "../../utils/time";
import toast from "react-hot-toast";
import { customToastSuccessStyle } from "../../configs/constants";

export default function ManageCoupons() {
  const nav = useNavigate();

  const [coupons, setCoupons] = useState([]);
  const [sports, setSports] = useState("");
  const [couponType, setCouponType] = useState("");

  const init = async () => {
    const couponData = await getCouponsApi();
    const data: any = [];
    for (const coupon of couponData) {
      data.push({ ...coupon });
    }
    // @ts-ignore
    setCoupons(data);
  };

  useEffect(() => {
    init();
  }, []);

  const tableData = useMemo(() => {
    if (sports === "") return coupons;
    return coupons?.filter((item: any) => item?.Sport === sports);
  }, [coupons, sports]);

  const filteredTableData = useMemo(() => {
    if (couponType === "") return tableData;

    const filteredCouponType = tableData?.filter(
      (item: any) => item["Coupon Type"].search(couponType) !== -1
    );

    return filteredCouponType;
  }, [tableData, couponType]);

  const deleteCouponHandler = async (id: string) => {
    await deleteCouponApi(id);
    init();
    toast("Deleted coupon.", customToastSuccessStyle);
  };

  const editCouponHandler = async (id: string) => {
    const items = coupons?.filter((item: any) => item?.id === id);
    if (items && items.length > 0) {
      nav("/admin/add_coupons", { state: items[0] });
    }
  };

  const columns = useMemo(() => {
    const data = [];
    if (Array.isArray(tableData)) {
      for (const key in tableData[0]) {
        if (key === "id") continue;
        data.push({
          name: capitalizeFirstLetter(key),
          selector: (row: any) => row[key],
          sortable: true,
        });
      }
      //extra key
      if (tableData[0]) {
        if (!("Multiplier" in tableData[0])) {
          data.push({
            name: "Multiplier",
            selector: (row: any) => row["Multiplier"] || "0",
            sortable: true,
          });
        }
        data.push({
          name: "Edit",
          selector: (row: any) => (
            <Button size="sm" onClick={() => editCouponHandler(row?.id)}>
              Edit
            </Button>
          ),
          sortable: false,
        });
        data.push({
          name: "Delete",
          selector: (row: any) => (
            <Button
              variant="danger"
              size="sm"
              onClick={() => deleteCouponHandler(row?.id)}
            >
              Delete
            </Button>
          ),
          sortable: false,
        });
      }
    }
    return data;
  }, [tableData]);

  const addCouponHandler = () => {
    nav("/admin/add_coupons");
  };

  return (
    <Form>
      <h2>Manage Coupons</h2>
      <Stack
        direction="horizontal"
        className="w-100 justify-content-md-between mt-5"
      >
        <Stack direction="horizontal">
          <Form.Select
            aria-label="Sports"
            onChange={(e) => {
              setSports(e.target.value);
            }}
            style={{ maxWidth: 250 }}
            defaultValue={sports}
          >
            <option value="">All</option>
            <option value="Football">Futbol</option>
            <option value="NBA Basketball">NBA Basketball</option>
            <option value="Other Basketball">Other Basketball</option>
            <option value="Cricket">Cricket</option>
          </Form.Select>
          <Form.Control
            type="text"
            aria-label="Sports"
            placeholder="Coupon Type"
            onChange={(e: any) => {
              setCouponType(e.target.value);
            }}
            style={{ maxWidth: 250, marginLeft: 20 }}
            defaultValue={couponType}
          ></Form.Control>
        </Stack>
        <Button variant="success" onClick={addCouponHandler}>
          Add Coupon
        </Button>
      </Stack>
      <Stack className="mt-4">
        <DataTable columns={columns} data={filteredTableData} pagination />
      </Stack>
    </Form>
  );
}
