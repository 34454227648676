import React, { useState, useEffect } from "react";
import { Container, Stack, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Web3 from "web3";

import { store } from "../store";

import { getMyTxListApi, swapDiamondToUsdtApi } from "../apis/coupon";
import { WRITE_DIAMOND_CONTRACT } from "../configs/smart_contracts";
import toast from "react-hot-toast";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../configs/constants";

export default function TxsTable() {
  const [account] = store.useState("account");
  const [userInfo, setUserInfo] = store.useState("userInfo");

  const [txs, setTxs] = useState([]);

  const init = async () => {
    const response = await getMyTxListApi(account);

    response.sort((a: any, b: any) => {
      if (a.createdAt < b.createdAt) {
        return 1;
      }
      if (a.createdAt > b.createdAt) {
        return -1;
      }
      return 0;
    });
    for (let i = 0; i < response.length; i++) {
      response[i].index = i;
    }
    setTxs(response);
  };

  useEffect(() => {
    init();
  }, [account]);

  const claimHandler = async (id: string, amount: any) => {
    try {
      const writeDiamondContract = await WRITE_DIAMOND_CONTRACT();

      await writeDiamondContract.methods
        .swapDiamondToUsdt(Web3.utils.toWei(amount, "ether"))
        .send({ from: account });

      const res = await swapDiamondToUsdtApi({
        id: id,
        wallet: account,
        amount: amount,
      });
      if (res) {
        await init();
        setUserInfo({
          ...(userInfo as any),
          diamond: parseFloat((userInfo as any).diamond) - parseFloat(amount),
        });
        toast("Swap diamond to usdt successfully", customToastSuccessStyle);
      } else {
        toast("Error to swap diamond to usdt", customToastStyle);
      }
    } catch (e: any) {
      toast(e.message, customToastStyle);
    } finally {
    }
  };

  const columns = [
    {
      name: "No",
      selector: (row: any) => row.index + 1,
      sortable: true,
    },
    {
      name: "Diamond Amount",
      selector: (row: any) => row.diamondAmount + " Diamond",
      sortable: true,
    },
    {
      name: "Total Diamond Balance",
      selector: (row: any) => row["totalDiamondBalance"] + " Diamond",
      sortable: true,
    },
    {
      name: "USDT Amount",
      selector: (row: any) => row.usdtAmount + " USDT",
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row: any) => row.createdAt,
      sortable: true,
    },
    {
      name: "Updated At",
      selector: (row: any) => row.updated_at,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: any) => row.allowed_status || "Pending",
      sortable: true,
    },
    {
      name: "Claim",
      selector: (row: any) =>
        row.allowed_status === "Allowed" ? (
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              claimHandler(row?.id, row?.diamondAmount);
            }}
          >
            Claim
          </Button>
        ) : null,
      sortable: true,
    },
  ];

  return (
    <Container>
      <Stack
        direction="horizontal"
        className="w-100 justify-content-md-between mt-5"
      >
        <h1>My Transactions (Diamond to USDT)</h1>
      </Stack>
      <Stack className="mt-4">
        <DataTable columns={columns} data={txs} pagination />
      </Stack>
    </Container>
  );
}
