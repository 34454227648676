import React, { useState, useEffect } from "react";
import { Button, Container, Stack } from "react-bootstrap";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
// @ts-ignore
import { confirm } from "react-bootstrap-confirmation";

import { getPayoutListApi, setPayoutStatusApi } from "../../apis/coupon";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../../configs/constants";

export default function TxsTable() {
  const [txs, setTxs] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getPayoutListApi();
      response.sort((a: any, b: any) => {
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        return 0;
      });
      for (let i = 0; i < response.length; i++) {
        response[i].index = i;
      }
      setTxs(response);
    })();
  }, []);

  const approveRequestHandler = async (id: string) => {
    const sure = await confirm("Are you sure you want to approve?");
    if (!sure) {
      return;
    }

    const res = await setPayoutStatusApi({ id: id, status: "Allowed" });

    if (res) {
      toast("Transaction approved successfully", customToastSuccessStyle);
      const _txs = [...txs];

      const updatedTx: any = _txs.find((tx: any) => tx.id === id);
      updatedTx.allowed_status = "Allowed";
      setTxs(_txs);
    } else {
      toast("Error to approve the transaction", customToastStyle);
    }
  };

  const rejectRequestHandler = async (id: string) => {
    const sure = await confirm("Are you sure you want to reject?");
    if (!sure) {
      return;
    }

    const res = await setPayoutStatusApi({ id: id, status: "Rejected" });
    if (res) {
      toast("Reject transaction successfully", customToastSuccessStyle);
      const _txs = [...txs];

      const updatedTx: any = _txs.find((tx: any) => tx.id === id);
      updatedTx.allowed_status = "Rejected";
      setTxs(_txs);
    } else {
      toast("Error to reject the transaction", customToastStyle);
    }
  };

  const columns = [
    {
      name: "No",
      selector: (row: any) => row.index + 1,
      sortable: true,
    },
    {
      name: "Diamond Amount",
      selector: (row: any) => row.diamondAmount + " Diamond",
      sortable: true,
    },
    {
      name: "Total Diamond Balance",
      selector: (row: any) => row["totalDiamondBalance"] + " Diamond",
      sortable: true,
    },
    {
      name: "USDT Amount",
      selector: (row: any) => row.usdtAmount + " USDT",
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row: any) => row.createdAt,
      sortable: true,
    },
    {
      name: "Updated At",
      selector: (row: any) => row.updated_at,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: any) => row.allowed_status || "Pending",
      sortable: true,
    },
    {
      name: "Approve",
      selector: (row: any) =>
        row.allowed_status ? null : (
          <Button
            size="sm"
            onClick={() => {
              approveRequestHandler(row.id);
            }}
          >
            Approve
          </Button>
        ),
      sortable: true,
    },
    {
      name: "Reject",
      selector: (row: any) =>
        row.allowed_status ? null : (
          <Button
            size="sm"
            variant="danger"
            onClick={() => {
              rejectRequestHandler(row.id);
            }}
          >
            Reject
          </Button>
        ),
      sortable: true,
    },
  ];

  return (
    <Container>
      <Stack
        direction="horizontal"
        className="w-100 justify-content-md-between mt-5"
      >
        <h1>Payout list (Diamond to USDT)</h1>
      </Stack>
      <Stack className="mt-4">
        <DataTable columns={columns} data={txs} pagination />
      </Stack>
    </Container>
  );
}
