import React, { useState, useEffect } from "react";
import { Container, Stack, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";

import { store } from "../store";
import DiamondImg from "../assets/img/diamond.png";
import CouponImg from "../assets/img/coin.png";

import {
  getMyPredictionCouponListApi,
  getMyPredictionDiamondListApi,
  getGamesApi,
  setClaimCouponApi,
  setClaimDiamondApi,
} from "../apis/coupon";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../configs/constants";

export default function PredictionTable() {
  const [account] = store.useState("account");
  const [userInfo, setUserInfo] = store.useState("userInfo");

  const [tableData, setTableData]: [tableData: any, setTableData: Function] =
    useState([]);

  const init = async () => {
    const couponList = await getMyPredictionCouponListApi(account);
    const diamondList = await getMyPredictionDiamondListApi(account);

    const games = await getGamesApi();

    const couponData: any = couponList.map((coupon: any, index: number) => {
      const game = games.find((game: any) => game.id === coupon.gameId);

      let coupons = [];
      try {
        coupons = JSON.parse(game?.coupons);
      } catch (e) {}

      let isWinning = "";

      for (let i = 0; i < coupons.length; i++) {
        if (
          coupons[i].couponType === coupon.couponType &&
          coupons[i].couponValue === coupon?.couponValue
        ) {
          isWinning = "winner";
        }
      }

      let claimed = false;
      const claimCoupons = (userInfo as any).claimCoupons || [];
      if (claimCoupons.includes(coupon?.id)) {
        claimed = true;
      }

      return {
        ...coupon,
        type: "Coupon",
        sports: game?.sports,
        home: game?.home,
        away: game?.away,
        coupons: coupons,
        isWinning,
        claimed,
      };
    });
    const diamondData: any = diamondList.map((diamond: any, index: number) => {
      const game = games.find((game: any) => game.id === diamond.gameId);

      let coupons = [];
      try {
        coupons = JSON.parse(game?.coupons);
      } catch (e) {}

      let isWinning = "";

      for (let i = 0; i < coupons.length; i++) {
        if (
          coupons[i].couponType === diamond.couponType &&
          coupons[i].couponValue === diamond?.couponValue
        ) {
          isWinning = "winner";
        }
      }

      let claimed = false;
      const claimDiamonds = (userInfo as any).claimDiamonds || [];
      if (claimDiamonds.includes(diamond?.id)) {
        claimed = true;
      }

      return {
        ...diamond,
        type: "Diamond",
        sports: game?.sports,
        home: game?.home,
        away: game?.away,
        coupons: coupons,
        isWinning,
        claimed,
      };
    });

    const data = [...diamondData, ...couponData];

    for (let i = 0; i < data.length; i++) {
      data[i]["index"] = i;
    }

    setTableData(data);
  };

  useEffect(() => {
    init();
  }, [account, userInfo]);

  const claimHandler = async (row: any) => {
    let res = false;
    if (row.type === "Coupon") {
      res = await setClaimCouponApi({
        wallet: account,
        betCouponId: row?.id,
      });
    } else {
      // Diamond
      res = await setClaimDiamondApi({
        wallet: account,
        betDiamondId: row?.id,
        amount: row?.diamondAmount,
      });
    }

    if (res === true) {
      if (row.type === "Coupons") {
        const claimCoupons = [...(userInfo as any)?.claimCoupons];
        claimCoupons.push(row.id);
        setUserInfo({
          ...(userInfo as any),
          claimCoupons: claimCoupons,
        });
      } else {
        const claimDiamonds = [...(userInfo as any)?.claimDiamonds];
        claimDiamonds.push(row.id);
        setUserInfo({
          ...(userInfo as any),
          claimDiamonds: claimDiamonds,
        });
      }
      toast("Get reward successfully", customToastSuccessStyle);
    } else {
      toast("Error to get reward", customToastStyle);
    }
  };

  const columns = [
    {
      name: "No",
      selector: (row: any) => row.index + 1,
      sortable: true,
    },
    {
      name: "Prediction Type",
      selector: (row: any) =>
        row.type === "Diamond" ? (
          <div>
            <img src={DiamondImg} alt="diamond" style={{ width: 20 }} /> Diamond
          </div>
        ) : (
          <div>
            <img src={CouponImg} alt="coupon" style={{ width: 25 }} /> Coupon
          </div>
        ),
      sortable: true,
    },
    {
      name: "Sports",
      selector: (row: any) => row.sports,
      sortable: true,
    },
    {
      name: "Home",
      selector: (row: any) => row.home,
      sortable: true,
    },
    {
      name: "Away",
      selector: (row: any) => row.away,
      sortable: true,
    },
    {
      name: "Coupon Type",
      selector: (row: any) => row.couponType,
      sortable: true,
    },
    {
      name: "Coupon Value",
      selector: (row: any) => row.couponValue,
      sortable: true,
    },
    {
      name: "Diamond Amount",
      selector: (row: any) => row.diamondAmount || "",
      sortable: true,
    },
    {
      name: "Winning Coupon",
      selector: (row: any) =>
        row.isWinning === "winner" ? (
          row.claimed ? (
            <Button size="sm" variant="default" disabled>
              Claimed
            </Button>
          ) : (
            <Button
              variant="success"
              size="sm"
              onClick={() => {
                claimHandler(row);
              }}
            >
              Claim Reward
            </Button>
          )
        ) : (
          ""
        ),
      sortable: true,
    },
  ];

  return (
    <Container>
      <Stack
        direction="horizontal"
        className="w-100 justify-content-md-between mt-5"
      >
        <h1>My Predictions</h1>
      </Stack>
      <Stack className="mt-4">
        <DataTable columns={columns} data={tableData} pagination />
      </Stack>
    </Container>
  );
}
