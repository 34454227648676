import { Modal, Button, Form } from "react-bootstrap";

export default function SelectGameFortune({
  show,
  handleSelect,
  handleNext,
}: {
  show: boolean;
  handleSelect: Function;
  handleNext: () => void;
}) {
  const SPORTS = ["Football", "NBA Basketball", "Basketball", "Cricket"];

  const nextHandler = async () => {
    handleNext();
  };

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title style={{ fontSize: 18, fontWeight: "bold" }}>
          Sport you would like to make prediction in
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {SPORTS.map((type) => (
            <Form.Check
              type={"radio"}
              label={type === "Football" ? "Futbol" : type}
              id={`default-${type}`}
              name="sport"
              onChange={(e) => handleSelect(type)}
            />
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={nextHandler}>
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
