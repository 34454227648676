import React, { useState } from "react";
import { Stack } from "react-bootstrap";

import Layout from "../layout";
import Header from "../components/Header";
import KenoComponent from "../components/Keno";

export default function Keno() {
  return (
    <Layout>
      <div className="page-container">
        <div style={{ zIndex: 2 }} className="w-100">
          <Header />
          <Stack
            className="p-4 mt-2 d-flex align-items-center"
            style={{ zIndex: 2, position: "relative" }}
          >
            <h1>KENO </h1>
            <KenoComponent />
          </Stack>
        </div>
      </div>
    </Layout>
  );
}
