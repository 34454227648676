import React, { useMemo } from "react";
// @ts-ignore
import WinImg from "../../assets/img/win.png";

export default function PrizeCell({
  prize,
  delay,
}: {
  prize: number;
  delay: number;
}) {
  const prizeNumber = useMemo(() => {
    let count = 0;
    const maxDelayCount = 15;

    if (prize < 2) {
      count = 0;
    } else if (2 <= prize && prize <= 7) {
      count = 1;
    } else if (prize <= 11) {
      count = 2;
    } else if (delay > maxDelayCount) {
      count = 3;
    } else {
      count = 4;
    }
    return count;
  }, [prize, delay]);
  return (
    <div className="cell prize-cell" style={{position: "relative"}}>
      {prize === 14 && (
        <img
          src={WinImg}
          alt="win"
          style={{ width: 40, height: 40, position: "absolute", top: -40 }}
        />
      )}
      <div>{prizeNumber}</div>
      <div style={{ fontSize: 8 }}>coupon</div>
    </div>
  );
}
