import React, { useState } from "react";
import { Button, Container, Stack, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaBasketball } from "react-icons/fa6";
import { FaCubesStacked } from "react-icons/fa6";
import { FaArrowsSpin } from "react-icons/fa6";
import { FaDiamond } from "react-icons/fa6";
import { FaWandSparkles } from "react-icons/fa6";
import { FaRightLeft } from "react-icons/fa6";
import { FaBorderNone } from "react-icons/fa6";

import Layout from "../layout";
import Header from "../components/Header";
import useResponsive from "../hooks/useResponsive";
import PlayTowerMaster from "../components/Dashboard/PlayTowerMaster";
import PlayWheelFortune from "../components/Dashboard/PlayWheelFortune";

export default function Dashboard() {
  const nav = useNavigate();
  const [showTowerMaster, setShowTowerMaster] = useState(false);
  const [showWheel, setShowWheel] = useState(false);

  const isSmall = useResponsive(1040);

  return (
    <Layout>
      <div className="page-container">
        <PlayTowerMaster
          show={showTowerMaster}
          handleClose={() => setShowTowerMaster(false)}
          handleOpen={() => setShowTowerMaster(true)}
        />
        <PlayWheelFortune
          show={showWheel}
          handleClose={() => setShowWheel(false)}
          handleOpen={() => setShowWheel(true)}
        />
        <div style={{ zIndex: 2 }} className="w-100">
          <Header />
          <div className="index-page-container">
            <Container>
              <Stack
                className="justify-content-md-center"
                direction={isSmall ? "vertical" : "horizontal"}
                gap={5}
              >
                <Button
                  size="lg"
                  style={{ minWidth: 220, minHeight: 100 }}
                  className="action-button"
                  onClick={() => setShowTowerMaster(true)}
                >
                  <FaCubesStacked style={{ marginRight: 10 }} size={28} />
                  Tower Master
                </Button>
                <Button
                  size="lg"
                  style={{ minWidth: 220, minHeight: 100 }}
                  className="action-button"
                  onClick={() => setShowWheel(true)}
                >
                  <FaArrowsSpin style={{ marginRight: 10 }} size={28} />
                  Wheel of Fortune
                </Button>
                <Button
                  size="lg"
                  style={{ minWidth: 220, minHeight: 100 }}
                  className="action-button"
                  onClick={() => nav("/buy/coupons")}
                >
                  <FaBasketball style={{ marginRight: 10 }} size={28} />
                  Get Coupons
                </Button>
                {/* <Button
                  size="lg"
                  style={{ minWidth: 220, minHeight: 100 }}
                  className="action-button"
                  onClick={() => nav("/buy/diamonds")}
                >
                  <FaDiamond style={{ marginRight: 10 }} size={24} />
                  Get Diamonds
                </Button> */}
              </Stack>
              <Stack
                className="justify-content-md-center"
                direction={isSmall ? "vertical" : "horizontal"}
                gap={5}
                style={{ marginTop: 30 }}
              >
                <Button
                  size="lg"
                  style={{ minWidth: 220, minHeight: 100 }}
                  className="action-button"
                  onClick={() => nav("/games")}
                >
                  <FaWandSparkles style={{ marginRight: 10 }} size={28} />
                  Sports Bets
                </Button>
                <Button
                  size="lg"
                  style={{ minWidth: 220, minHeight: 100 }}
                  className="action-button"
                  onClick={() => nav("/swap/diamond")}
                >
                  <FaRightLeft style={{ marginRight: 10 }} size={28} />
                  Swap Diamonds
                </Button>
                <Button
                  size="lg"
                  style={{ minWidth: 220, minHeight: 100 }}
                  className="action-button"
                  onClick={() => nav("/buy/diamonds")}
                >
                  <FaDiamond style={{ marginRight: 10 }} size={24} />
                  Get Diamonds
                </Button>
                <Button
                  size="lg"
                  style={{ minWidth: 220, minHeight: 100 }}
                  className="action-button"
                  onClick={() => nav("/keno")}
                >
                  <FaBorderNone style={{ marginRight: 8 }} size={24} />
                  KENO
                </Button>
              </Stack>
            </Container>
          </div>
        </div>
      </div>
    </Layout>
  );
}
