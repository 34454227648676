import { useState, useEffect, useMemo } from "react";
import { Modal, Button, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { store } from "../../store";
import { getSpinAvailableApi } from "../../apis/coupon";
import { customToastStyle } from "../../configs/constants";

export default function PlayWheelFortune({
  show,
  handleClose,
  handleOpen,
}: {
  show: boolean;
  handleClose: () => void;
  handleOpen: () => void;
}) {
  const nav = useNavigate();

  const [account] = store.useState("account");
  const [userInfo, setUserInfo] = store.useState("userInfo");

  const credit = (userInfo as any)?.credit || 0;

  const [available, setAvailable] = useState(false);

  const creditAvailable = useMemo(() => {
    return parseFloat((userInfo as any)?.credit || 0) === 0 && available;
  }, [available, userInfo]);

  useEffect(() => {
    (async () => {
      if (account) {
        setAvailable(await getSpinAvailableApi(account));
      }
    })();
  }, [account]);

  const playFortuneHandler = async () => {
    if (parseFloat(credit) < 1) {
      return toast("You do not have enough credit", customToastStyle);
    }
    handleClose();
    nav("/spin/fortune");
  };

  return (
    <Modal show={show} onHide={handleClose} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Play Wheel of Fortune</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          <ListGroup.Item>
            <Button className="w-100" onClick={() => nav("/buy/credits")}>
              Buy Credits
            </Button>
          </ListGroup.Item>
          <ListGroup.Item>
            <Button
              className="w-100"
              onClick={() => nav("/spin")}
              disabled={!creditAvailable}
            >
              Free Credits{" "}
              {!creditAvailable && "(You can only play once every 24 hours)"}
            </Button>
          </ListGroup.Item>
          <ListGroup.Item>
            <Button className="w-100" onClick={playFortuneHandler}>
              Fortune spin (1 credit)
            </Button>
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
