import React, { useEffect, useState } from "react";
import { Card, Container, Form, Image, Button, Stack } from "react-bootstrap";
import toast from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";

import { getDiamondPriceApi, setDiamondPriceApi } from "../../apis/coupon";
import DiamondImg from "../../assets/img/diamond.png";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../../configs/constants";
import {
  WRITE_DIAMOND_CONTRACT,
  READ_DIAMOND_CONTRACT,
} from "../../configs/smart_contracts";
import { store } from "../../store";

export default function ManageDiamond() {
  const [amount, setAmount] = useState("");
  const [diamondPrice, setDiamondPrice] = useState("0");
  const [loading, setLoading] = useState(false);

  const [account] = store.useState("account");

  useEffect(() => {
    (async () => {
      setDiamondPrice(await getDiamondPriceApi());
    })();
  }, []);

  const saveCreditHandler = async () => {
    if (loading) return;
    const owner = await READ_DIAMOND_CONTRACT.methods.owner().call();
    if (account !== owner) {
      return toast("Only the owner can set diamond price", customToastStyle);
    }
    if (isNaN(parseFloat(amount))) {
      return toast("Please enter a valid amount", customToastStyle);
    }
    try {
      setLoading(true);
      const writeCreditContract = await WRITE_DIAMOND_CONTRACT();
      await writeCreditContract.methods
        .setTokenPrice(amount, 1)
        .send({ from: account });
      const res = await setDiamondPriceApi(amount);
      if (res) {
        toast("Diamond price has been saved", customToastSuccessStyle);
        setDiamondPrice(amount);
        setAmount("");
      } else {
        toast("Error to save diamond price", customToastStyle);
      }
    } catch (e) {
      toast("Error saving diamond price", customToastStyle);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container style={{ marginTop: 50 }}>
      <h2>
        Manage Diamonds <Image src={DiamondImg} width={50} />
      </h2>
      <Stack direction="vertical">
        <Stack direction="horizontal">
          <Card className="w-50 m-2">
            <Card.Body>
              <Card.Title>Diamond Price</Card.Title>
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>
                  Price of Diamond ($1 = {diamondPrice} Diamonds)
                </Form.Label>
                <Form.Control
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-3">
                <Button
                  onClick={saveCreditHandler}
                  disabled={loading}
                  style={{ minWidth: 80 }}
                >
                  {loading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form.Group>
            </Card.Body>
          </Card>
        </Stack>
      </Stack>
    </Container>
  );
}
