import { Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.scss";
import WelcomePage from "./pages/Welcome";
import Page404 from "./pages/404";
import GamesPage from "./pages/Games";
import SpinPage from "./pages/Spin";
import FortuneSpinPage from "./pages/FortuneSpin";
import AdminPage from "./pages/Admin";
import BuyCredits from "./pages/BuyCredits";
import BuyDiamonds from "./pages/BuyDiamonds";
import SwapDiamond from "./pages/SwapDiamond";
import TowerMaster from "./pages/TowerMaster";
import Keno from "./pages/Keno";
import MyCoupon from "./pages/MyCoupon";
import MyPrediction from "./pages/MyPrediction";
import MyTxs from "./pages/MyTxs";
import BetDiamond from "./pages/BetDiamond";

import ProtectedComponent from "./components/ProtectedComponent";
import ProtectedAdmin from "./components/ProtectedAdmin";
import BuyCoupons from "./pages/BuyCoupons";
import DashboardPage from "./pages/Dashboard";
import AdminLogin from "./pages/AdminLogin";

export enum BgColor {
  RED = "#e8583c",
  YELLOW = "#ffdb0a",
  BLUE = "#10c9f2",
  GREY = "#d9d9d9",
  DARKBLUE = "#2775ca",
  GREEN = "#37D647",
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<WelcomePage />} />
      <Route path="/404" element={<Page404 />} />
      <Route
        path="/spin"
        element={
          <ProtectedComponent>
            <SpinPage />
          </ProtectedComponent>
        }
      />
      <Route
        path="/spin/fortune"
        element={
          <ProtectedComponent>
            <FortuneSpinPage />
          </ProtectedComponent>
        }
      />
      <Route path="/tower_master" element={<TowerMaster />} />
      <Route path="/keno" element={<Keno />} />
      <Route
        path="/games"
        element={
          <ProtectedComponent>
            <GamesPage />
          </ProtectedComponent>
        }
      />
      <Route path="/admin/" element={<AdminLogin />} />
      <Route
        path="/admin/:sub_url"
        element={
          <ProtectedAdmin>
            <AdminPage />
          </ProtectedAdmin>
        }
      />
      <Route
        path="dashboard"
        element={
          <ProtectedComponent>
            <DashboardPage />
          </ProtectedComponent>
        }
      />
      <Route
        path="/buy/credits"
        element={
          <ProtectedComponent>
            <BuyCredits />
          </ProtectedComponent>
        }
      />
      <Route
        path="/buy/diamonds"
        element={
          <ProtectedComponent>
            <BuyDiamonds />
          </ProtectedComponent>
        }
      />
      <Route
        path="/swap/diamond"
        element={
          <ProtectedComponent>
            <SwapDiamond />
          </ProtectedComponent>
        }
      />
      <Route
        path="/buy/coupons"
        element={
          <ProtectedComponent>
            <BuyCoupons />
          </ProtectedComponent>
        }
      />
      <Route
        path="/my-coupons"
        element={
          <ProtectedComponent>
            <MyCoupon />
          </ProtectedComponent>
        }
      />
      <Route
        path="/my-prediction"
        element={
          <ProtectedComponent>
            <MyPrediction />
          </ProtectedComponent>
        }
      />
      <Route
        path="/bet/diamond"
        element={
          <ProtectedComponent>
            <BetDiamond />
          </ProtectedComponent>
        }
      />
      <Route
        path="/my-txs"
        element={
          <ProtectedComponent>
            <MyTxs />
          </ProtectedComponent>
        }
      />
      <Route path="/*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
}

export default App;
