import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { connectWallet } from "../utils";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../configs/constants";
import { store } from "../store";
import {
  getUserApi,
  getSignMessageApi,
  setSignMessageApi,
} from "../apis/coupon";

export default function SignUp({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}) {
  const nav = useNavigate();

  const [, setAccount] = store.useState("account");
  const [, setUserInfo] = store.useState("userInfo");

  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSign = async () => {
    if (!agree) {
      return toast(
        "Please confirm this terms of service to sign up.",
        customToastStyle
      );
    }
    try {
      setLoading(true);
      const web3: any = await connectWallet();
      const accounts = await web3.eth.getAccounts();
      const SIGN_MESSAGE = await getSignMessageApi();
      const hash = web3.utils.sha3(SIGN_MESSAGE);
      // @ts-ignore
      const signature = await window.ethereum.request({
        method: "personal_sign",
        params: [hash, accounts[0]],
      });

      console.log("signature", signature);

      localStorage.setItem("wallet", accounts[0]);

      await setSignMessageApi({ address: accounts[0], signature: signature });

      setAccount(accounts[0]);
      setUserInfo(await getUserApi(accounts[0]));

      handleClose();
      toast("Sign up successfully", customToastSuccessStyle);
      nav("/dashboard");
    } catch (e) {
      console.log(e);
      toast("Error to sign up", customToastStyle);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal show={show} onHide={handleClose} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Sign Up</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>Tower Master Terms of Service</h3>
        <div>
          <b>Effective Date: August 12, 2024</b>
        </div>
        <p>
          Welcome to Tower Master! By accessing or using our game, you agree to
          these Terms of Service. Please read them carefully.
        </p>
        <p>
          1. Acceptance of Terms By accessing or using Tower Master, you agree
          to be bound by these Terms of Service, our Privacy Policy, and any
          additional terms and conditions that may apply to specific features of
          the Game. If you do not agree with any part of these Terms, you should
          not use the Game.
        </p>
        <p>
          2. Eligibility You must be at least 13 years old to use Tower Master.
          If you are under 18, you must have the consent of your parent or
          guardian to use the Game. By using the Game, you represent and warrant
          that you meet these eligibility requirements.
        </p>
        <p>
          3. Account Creation and Security To play Tower Master, you may need to
          create an account. You are responsible for maintaining the
          confidentiality of your account information and for all activities
          that occur under your account. You agree to notify us immediately of
          any unauthorized use of your account or any other breach of security.
        </p>
        <p>
          4. Intellectual Property All content, including but not limited to
          text, graphics, logos, images, and software, is the property of Tower
          Master or its licensors and is protected by copyright, trademark, and
          other intellectual property laws. You may not use, copy, modify, or
          distribute any content from the Game without our prior written
          consent.
        </p>
        <p>
          5. User Conduct You agree to use Tower Master in accordance with these
          Terms and all applicable laws. You agree not to: Engage in any
          activity that disrupts or interferes with the Game's servers or
          networks. Attempt to gain unauthorized access to any part of the Game,
          other users' accounts, or any related systems or networks. Use any
          automated means to access, scrape, or collect data from the Game. Post
          or transmit any content that is unlawful, defamatory, obscene, or
          otherwise objectionable.
        </p>
        <p>
          6. Termination We reserve the right to suspend or terminate your
          access to Tower Master at our sole discretion, without notice, for any
          reason, including but not limited to violations of these Terms or any
          other applicable policies.
        </p>
        <p>
          7. Contact Us If you have any questions or concerns about these Terms
          or the Game, please contact us at: Email: support@towermastergame.com
          Thank you for playing Tower Master! Enjoy the game!
        </p>
        <div>
          <input
            type="checkbox"
            className="m-2"
            checked={agree}
            onChange={(e) => {
              setAgree(e.target.checked);
            }}
          />
          Agree Terms of Service
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSign} disabled={loading}>
          Sign Message
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
