import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../store";

export default function ProtectedAdmin(props: any) {
  const nav = useNavigate();
  const [account] = store.useState("account");

  useEffect(() => {
    if (
      !account ||
      !(
        process.env.REACT_APP_OWNER === account ||
        account === "0xdB2981154407E050562fEDbfd039D216050002E3" ||
        account === "0xcD889634eb2495CAB8816eA853b6838f3921165d"
      )
    ) {
      return nav("/");
    }
  }, [account, nav]);

  return props.children;
}
