import { getHttpWeb3, connectWallet } from "../utils";
import {
  CREDIT_ADDRESS,
  USDT_ADDRESS,
  DIAMOND_ADDRESS,
  COUPON_ADDRESS,
} from "./addresses";

import CreditAbi from "./abi/credit.json";
import DiamondAbi from "./abi/diamond.json";
import UsdtAbi from "./abi/usdt.json";
import CouponAbi from "./abi/coupon.json";

const httpWeb3 = getHttpWeb3();

export const READ_CREDIT_CONTRACT = new httpWeb3.eth.Contract(
  CreditAbi,
  CREDIT_ADDRESS
);

export const READ_DIAMOND_CONTRACT = new httpWeb3.eth.Contract(
  DiamondAbi,
  DIAMOND_ADDRESS
);

export const READ_USDT_CONTRACT = new httpWeb3.eth.Contract(
  UsdtAbi,
  USDT_ADDRESS
);

export const READ_COUPON_CONTRACT = new httpWeb3.eth.Contract(
  CouponAbi,
  COUPON_ADDRESS
);

export const WRITE_CREDIT_CONTRACT = async () => {
  const web3 = await connectWallet();
  return new web3.eth.Contract(CreditAbi, CREDIT_ADDRESS);
};

export const WRITE_DIAMOND_CONTRACT = async () => {
  const web3 = await connectWallet();
  return new web3.eth.Contract(DiamondAbi, DIAMOND_ADDRESS);
};

export const WRITE_USDT_CONTRACT = async () => {
  const web3 = await connectWallet();
  return new web3.eth.Contract(UsdtAbi, USDT_ADDRESS);
};

export const WRITE_COUPON_CONTRACT = async () => {
  const web3 = await connectWallet();
  return new web3.eth.Contract(CouponAbi, COUPON_ADDRESS);
};
