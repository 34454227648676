import React, { useEffect, useState } from "react";
import { Wheel } from "react-custom-roulette";
import { Stack, Button } from "react-bootstrap";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";

import Layout from "../layout";
import Header from "../components/Header";
// @ts-ignore
import WheelAudio from "../assets/wheel.wav";
import WheelIndicator from "../assets/img/wheel-indicator.png";
import { setSpinScoreApi, getSpinAvailableApi } from "../apis/coupon";
import { store } from "../store";
import toast from "react-hot-toast";
import { customToastStyle } from "../configs/constants";

const data = [
  { option: "1" },
  { option: "2" },
  { option: "3" },
  { option: "4" },
  { option: "5" },
  { option: "6" },
];

function Spin(props: any) {
  const nav = useNavigate();

  const [account] = store.useState("account");
  const [userInfo, setUserInfo] = store.useState("userInfo");

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [available, setAvailable] = useState(false);
  const [finished, setFinished] = useState(false);
  const [claimed, setClaimed] = useState(false);

  const { width, height } = useWindowSize();

  useEffect(() => {
    (async () => {
      if (account) {
        setAvailable(await getSpinAvailableApi(account));
      }
    })();
  }, [account]);

  const handleSpinClick = async () => {
    if (!available) return toast("You already played spin", customToastStyle);

    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * data.length);
      await setSpinScoreApi(account, (newPrizeNumber + 1).toString());
      setAvailable(false);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
      setTimeout(() => {
        new Audio(WheelAudio).play();
      }, 800);
    }
  };

  const handleClaim = async () => {
    if (claimed) return;
    setClaimed(true);
    const credit = parseFloat((userInfo as any)?.credit) || 0;
    setUserInfo({
      ...(userInfo as any),
      credit: credit + prizeNumber + 1,
    });
    nav("/dashboard");
  };

  return (
    <Layout>
      {finished && <Confetti width={width} height={height} />}
      <div className="page-container">
        <Header />
        <div style={{ zIndex: 2 }}>
          <Stack
            style={{
              background: "#291e655e",
              alignItems: "center",
              borderRadius: 20,
            }}
            className="p-4 mt-5"
          >
            <h1>Free Credits</h1>
            {finished ? (
              <Stack
                style={{
                  alignItems: "center",
                }}
              >
                <h4 className="mt-5">You won {prizeNumber + 1} credits.</h4>
                <Button
                  className="action-button mt-3"
                  disabled={claimed}
                  onClick={handleClaim}
                >
                  Claim
                </Button>
              </Stack>
            ) : (
              <>
                <Wheel
                  mustStartSpinning={mustSpin}
                  prizeNumber={prizeNumber}
                  backgroundColors={["#fff"]}
                  data={data}
                  pointerProps={{
                    src: WheelIndicator,
                    style: { rotate: "45deg", width: 100 },
                  }}
                  radiusLineColor={"#b884f2"}
                  radiusLineWidth={3}
                  outerBorderColor={"#9b6ace"}
                  innerBorderColor="#b884f2"
                  innerBorderWidth={15}
                  onStopSpinning={() => {
                    setFinished(true);
                  }}
                />
                <Button
                  className="action-button mt-5"
                  onClick={handleSpinClick}
                  disabled={!available}
                >
                  SPIN
                </Button>
              </>
            )}
          </Stack>
        </div>
      </div>
    </Layout>
  );
}

export default Spin;
