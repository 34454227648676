import React, { useEffect, useState } from "react";
import { Button, Container, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Layout from "../layout";

function Page404(props: any) {
  const nav = useNavigate();

  return (
    <Layout>
      <div className="index-page-container">
        <div style={{ zIndex: 1 }}>
          <h1>404 Page not found</h1>
          <Container style={{ marginTop: 50 }}>
            <Stack
              className="justify-content-md-center"
              direction="horizontal"
              gap={5}
            >
              <Button
                variant="default"
                style={{ color: "white" }}
                onClick={() => nav("/dashboard")}
              >
                Go to Home
              </Button>
            </Stack>
          </Container>
        </div>
      </div>
    </Layout>
  );
}

export default Page404;
