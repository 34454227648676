import React, { useEffect, useState } from "react";
import { Button, Container, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BigNumber from "bignumber.js";
import toast from "react-hot-toast";
import Web3 from "web3";

import Layout from "../layout";
import Header from "../components/Header";
import SignUp from "../components/SignUp";

import { store } from "../store";
import useResponsive from "../hooks/useResponsive";

import { connectWallet } from "../utils";

import {
  customToastStyle,
  customToastSuccessStyle,
} from "../configs/constants";

import { getUserApi, getCouponsApi } from "../apis/coupon";

function Welcome(props: any) {
  const nav = useNavigate();

  const [showSignUp, setShowSignUp] = useState(false);

  const [account, setAccount] = store.useState("account");
  const [, setBalance] = store.useState("balance");
  const [, setUserInfo] = store.useState("userInfo");
  const [, setCoupons] = store.useState("coupons");

  useEffect(() => {
    (async () => {
      const couponData = await getCouponsApi();
      setCoupons(couponData);
    })();
  }, []);

  const isSmall = useResponsive(600);

  const handleSighUp = () => {
    setShowSignUp(true);
  };

  const handleShowSignUp = () => {
    setShowSignUp(false);
  };

  const registerHandler = async () => {
    const web3: any = await connectWallet();
    if (web3 && typeof web3 !== "string") {
      const accounts = await web3.eth.getAccounts();

      const _balance = await web3.eth.getBalance(accounts[0]);
      if (new BigNumber(_balance.toString()).isEqualTo(BigNumber("0"))) {
        toast("Your balance is 0", customToastStyle);
      }

      setBalance(
        Math.floor(parseFloat(Web3.utils.fromWei(_balance, "ether")) * 100) /
          100 || 0
      );

      toast("Connected success", customToastSuccessStyle);

      setUserInfo(await getUserApi(accounts[0]));
      setAccount(accounts[0]);
      nav("/dashboard");
    }
  };

  const connectWalletHandler = async () => {
    const account = localStorage.getItem("wallet");
    if (account) {
      await registerHandler();
    } else {
      handleSighUp();
    }
  };

  const handlePlayTowerMaster = () => {
    nav("/tower_master");
  };

  return (
    <Layout>
      {account && <Header />}
      <SignUp show={showSignUp} handleClose={handleShowSignUp} />
      <div className="index-page-container">
        <div style={{ zIndex: 2 }}>
          <h1>Welcome to Tower Master</h1>
          <Container style={{ marginTop: 50 }}>
            <Stack
              className="justify-content-md-center"
              direction={isSmall ? "vertical" : "horizontal"}
              gap={5}
            >
              <Button
                size="lg"
                style={{ minWidth: 150 }}
                className="action-button"
                onClick={connectWalletHandler}
              >
                Connect Wallet
              </Button>
              <Button
                size="lg"
                style={{ minWidth: 150 }}
                className="action-button"
                onClick={handlePlayTowerMaster}
              >
                Play Tower Master
              </Button>
            </Stack>
          </Container>
        </div>
      </div>
    </Layout>
  );
}

export default Welcome;
