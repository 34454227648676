import Layout from "../layout";
import Header from "../components/Header";
import PredictionTable from "../components/PredictionTable";

function MyPrediction(props: any) {
  return (
    <Layout>
      <div className="page-container">
        <div style={{ zIndex: 2 }} className="w-100">
          <Header />
          <PredictionTable />
        </div>
      </div>
    </Layout>
  );
}

export default MyPrediction;
