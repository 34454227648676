import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../store";

export default function ProtectedComponent(props: any) {
  const nav = useNavigate();
  const [account] = store.useState("account");

  useEffect(() => {
    if (!account) {
      return nav("/");
    }
  }, [account, nav]);

  return props.children;
}
