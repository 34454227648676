import { Toaster } from "react-hot-toast";
import { Image } from "react-bootstrap";

import BgImg from "../assets/img/bg.png";

function Layout(props: any) {
  return (
    <div className="layout">
      <Toaster position="bottom-right" />
      <div className="bg-container">
        <Image id="bg-img" src={BgImg} alt="background" />
      </div>
      {props.children}
    </div>
  );
}

export default Layout;
