import Layout from "../layout";
import Header from "../components/Header";
import GameTable from "../components/GameTable";

function Games(props: any) {


  return (
    <Layout>
      <div className="page-container">
        <div style={{zIndex:2}} className="w-100">
          <Header />
          <GameTable />
        </div>
      </div>
    </Layout>
  );
}

export default Games;
