import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import "../assets/login.css";

import BackgroundImage from "../assets/img/background.png";
import Logo from "../assets/img/logo.png";
import { store } from "../store";
import { getUserApi, getCouponsApi } from "../apis/coupon";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../configs/constants";

const ADMIN_ADDRESS = "0xdB2981154407E050562fEDbfd039D216050002E3";

const Login = () => {
  const nav = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [, setAccount] = store.useState("account");
  const [, setBalance] = store.useState("balance");
  const [, setUserInfo] = store.useState("userInfo");
  const [, setCoupons] = store.useState("coupons");
  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const couponData = await getCouponsApi();
      setCoupons(couponData);
    })();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    await delay(500);
    setLoading(false);
    console.log(`Username :${inputUsername}, Password :${inputPassword}`);
    if (inputUsername === "admin" && inputPassword === "admin123456") {
      setBalance(0);

      setUserInfo(await getUserApi(ADMIN_ADDRESS));
      setAccount(ADMIN_ADDRESS);

      nav("/dashboard");

      toast("Connected success", customToastSuccessStyle);
    } else {
      setShow(true);
    }
  };

  function delay(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  return (
    <div
      className="sign-in__wrapper"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {/* Overlay */}
      <div className="sign-in__backdrop"></div>
      {/* Form */}
      <Form
        className="shadow p-4 bg-white rounded"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {/* Header */}
        <img
          className="img-thumbnail mx-auto d-block mb-2"
          src={Logo}
          alt="logo"
        />
        <div className="h4 mb-2 text-center">Sign In</div>
        {/* ALert */}
        {show ? (
          <Alert
            className="mb-2"
            variant="danger"
            onClose={() => setShow(false)}
            dismissible
          >
            Incorrect username or password.
          </Alert>
        ) : (
          <div />
        )}
        <Form.Group className="mb-2" controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            value={inputUsername}
            placeholder="Username"
            onChange={(e) => setInputUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="mb-2" controlId="password">
          <Form.Label>Password</Form.Label>
          <div style={{ position: "relative" }}>
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={inputPassword}
              placeholder="Password"
              onChange={(e) => setInputPassword(e.target.value)}
              required
            />
            <button
              style={{
                position: "absolute",
                bottom: 5,
                right: 0,
                border: "none",
                background: "transparent",
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </Form.Group>
        <Form.Group className="mb-2" controlId="checkbox">
          <Form.Check type="checkbox" label="Remember me" />
        </Form.Group>
        {!loading ? (
          <Button
            className="w-100"
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            Log In
          </Button>
        ) : (
          <Button className="w-100" variant="primary" type="submit" disabled>
            Logging In...
          </Button>
        )}
      </Form>
      {/* Footer */}
      <div className="w-100 mb-2 position-absolute bottom-0 start-50 translate-middle-x text-white text-center">
        Tower Master Admin Section
      </div>
    </div>
  );
};

export default Login;
