import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Card,
  ListGroup,
} from "react-bootstrap";
import toast from "react-hot-toast";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import Table from "react-bootstrap/Table";

import {
  customToastStyle,
  customToastSuccessStyle,
} from "../../configs/constants";

import { store } from "../../store";
import { setCouponForGameApi } from "../../apis/coupon";

export default function AddScore({
  show,
  data,
  onHide,
  saveScore,
}: {
  show: boolean;
  data: any;
  onHide: () => void;
  saveScore: Function;
}) {
  const [coupons] = store.useState("coupons");

  const [couponType, setCouponType] = useState("");
  const [couponValue, setCouponValue] = useState("");
  const [payout, setPayout] = useState("");
  const [scores, setScores]: [scores: any, setScores: Function] = useState([]);
  const [loading, setLoading] = useState(false);

  const filteredCoupons = (coupons as any)?.filter(
    (item: any) => item?.Sport === data?.sports
  );

  const couponTypeList = useMemo(() => {
    const data: any = {};
    for (const coupon of filteredCoupons) {
      if (data[coupon["Coupon Type"]] === undefined) {
        data[coupon["Coupon Type"]] = "";
      }
    }
    return Object.keys(data);
  }, [filteredCoupons]);

  const couponValueList = useMemo(() => {
    try {
      if (couponType === "") {
        return [];
      }
      const filteredByType = filteredCoupons?.filter((item: any) => {
        return item["Coupon Type"] === couponType;
      });

      const data: any = {};
      for (const coupon of filteredByType) {
        if (data[coupon["Value"]] === undefined) {
          data[coupon["Value"]] = "";
        }
      }
      return Object.keys(data);
    } catch (e) {
      return [];
    }
  }, [filteredCoupons, couponTypeList]);

  const addScoreHandler = () => {
    if (couponType === "") {
      return toast("Select a coupon type", customToastStyle);
    }
    if (payout === "") {
      return toast("Please input payout amount", customToastStyle);
    }

    setScores([...scores, { couponType, couponValue, payout }]);

    setCouponType("");
    setCouponValue("");
    setPayout("");
  };

  const saveHandler = async () => {
    if (scores.length === 0) {
      return toast("Please add score", customToastStyle);
    }
    if (loading) {
      return false;
    }

    setLoading(true);

    const res = await setCouponForGameApi({
      id: data?.id,
      coupons: JSON.stringify(scores),
    });

    setLoading(false);

    if (res) {
      toast("Save score successfully", customToastSuccessStyle);
      onHide();
    } else {
      toast("Error to save score", customToastStyle);
    }
  };

  useEffect(() => {
    setCouponType("");
    setCouponValue("");
    setScores([]);
  }, [data]);

  return (
    <Modal show={show} onHide={onHide} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Add Score</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFile" className="mt-3">
            <Card className="mt-2">
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Label>Coupon Type</Form.Label>
                  </Col>
                  <Col>
                    <Form.Label>Coupon Value</Form.Label>
                  </Col>
                  <Col>
                    <Form.Label>Payout</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ListGroup>
                      {couponTypeList.map((item: any) => (
                        <ListGroup.Item
                          key={uuidv4()}
                          onClick={() => {
                            setCouponType(item);
                            setCouponValue("");
                          }}
                          active={item === couponType}
                          style={{ cursor: "pointer" }}
                        >
                          {item}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>
                  <Col>
                    <Form.Select
                      aria-label="Coupon"
                      value={couponValue}
                      onChange={(e) => {
                        setCouponValue(e.target.value);
                      }}
                    >
                      <option value="">Please select option</option>
                      {couponValueList.map((item: any) => (
                        <option key={uuidv4()}>{item}</option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      value={payout}
                      onChange={(e) => setPayout(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row
                  className="m-2 mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button size="sm" onClick={addScoreHandler}>
                    Add Score
                  </Button>
                </Row>
              </Card.Body>
            </Card>
            {scores.length > 0 && (
              <Card className="mt-4">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Coupon Type</th>
                      <th>Coupon Value</th>
                      <th>Payout Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scores.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item["couponType"]}</td>
                          <td>{item["couponValue"]}</td>
                          <td>{item["payout"]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={saveHandler}>Save</Button>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
