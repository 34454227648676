import React, { useEffect, useState } from "react";
import { Card, Container, Form, Button, Stack } from "react-bootstrap";
import toast from "react-hot-toast";

import {
  getKenoTimeLimitTowerMasterApi,
  getKenoMaxBetApi,
  setKenoTimeLimitTowerMasterApi,
  setKenoMaxBetAmountApi,
} from "../../apis/coupon";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../../configs/constants";

export default function ManageKeno() {
  const [dbKenoTimeLimit, setDbKenoTimeLimit] = useState("0");
  const [kenoTimeLimit, setKenoTimeLimit] = useState("");
  const [maxBetAmount, setMaxBetAmount] = useState("");
  const [dbMaxBetAmount, setDbMaxBetAmount] = useState("0");

  useEffect(() => {
    (async () => {
      setDbKenoTimeLimit(await getKenoTimeLimitTowerMasterApi());
      setDbMaxBetAmount(await getKenoMaxBetApi());
    })();
  }, []);

  const saveKenoTimeLimitHandler = async () => {
    if (isNaN(parseFloat(kenoTimeLimit))) {
      return toast("Please enter a valid time", customToastStyle);
    }
    const res = await setKenoTimeLimitTowerMasterApi(kenoTimeLimit);
    if (res) {
      toast("Set time limit for KENO", customToastSuccessStyle);
      setDbKenoTimeLimit(kenoTimeLimit);
      setKenoTimeLimit("");
    } else {
      toast("Error to save time limit", customToastStyle);
    }
  };

  const saveMaxBetHandler = async () => {
    if (isNaN(parseFloat(maxBetAmount))) {
      return toast("Please enter a valid number", customToastStyle);
    }
    const res = await setKenoMaxBetAmountApi(maxBetAmount);
    if (res) {
      toast("Save max bet amount successfully", customToastSuccessStyle);
      setDbMaxBetAmount(maxBetAmount);
      setMaxBetAmount("");
    } else {
      toast("Error to save max bet amount", customToastStyle);
    }
  };

  return (
    <Container style={{ marginTop: 50 }}>
      <h2>Manage Keno Game</h2>
      <Stack direction="vertical">
        <Stack direction="horizontal">
          <Card className="w-50 m-2">
            <Card.Body>
              <Card.Title>Time limit of KENO</Card.Title>
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>
                  Current time limit {dbKenoTimeLimit} minutes
                </Form.Label>
                <Form.Control
                  type="number"
                  value={kenoTimeLimit}
                  onChange={(e) => setKenoTimeLimit(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-3">
                <Button
                  onClick={saveKenoTimeLimitHandler}
                  style={{ minWidth: 80 }}
                >
                  Save
                </Button>
              </Form.Group>
            </Card.Body>
          </Card>
          <Card className="w-50 m-2">
            <Card.Body>
              <Card.Title>Max bet amount of KENO Game</Card.Title>
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>
                  Current max bet amount: {dbMaxBetAmount}
                </Form.Label>
                <Form.Control
                  type="number"
                  value={maxBetAmount}
                  onChange={(e) => setMaxBetAmount(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-3">
                <Button onClick={saveMaxBetHandler} style={{ minWidth: 80 }}>
                  Save
                </Button>
              </Form.Group>
            </Card.Body>
          </Card>
        </Stack>
      </Stack>
    </Container>
  );
}
