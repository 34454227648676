import React, { useState, useMemo, useEffect } from "react";
import { Container, Form, Stack } from "react-bootstrap";
import DataTable from "react-data-table-component";

import { store } from "../store";

export default function CouponTable() {
  const [userInfo] = store.useState("userInfo");
  const myCoupons = (userInfo as any)?.coupons || {};
  const [allCoupons] = store.useState("coupons");

  const tableData = useMemo(() => {
    const couponIds = Object.keys(myCoupons) || [];
    const result = [];
    let resultIndex = 0;
    for (const couponId of couponIds) {
      const index = (allCoupons as any).findIndex(
        (coupon: any) => coupon.id === couponId
      );
      const _coupon = (allCoupons as any)[index];
      result.push({
        ..._coupon,
        hold: myCoupons[couponId],
        index: resultIndex,
      });
      resultIndex++;
    }
    return result;
  }, [userInfo, allCoupons, myCoupons]);

  const columns = [
    {
      name: "No",
      selector: (row: any) => row.index + 1,
      sortable: true,
    },
    {
      name: "Sport",
      selector: (row: any) => row.Sport,
      sortable: true,
    },
    {
      name: "Coupon Type",
      selector: (row: any) => row["Coupon Type"],
      sortable: true,
    },
    {
      name: "Value",
      selector: (row: any) => row.Value,
      sortable: true,
    },
    {
      name: "Total Count",
      selector: (row: any) => row.hold,
      sortable: true,
    },
    {
      name: "Active Count",
      selector: (row: any) => row.hold,
      sortable: true,
    },
    {
      name: "Used Count",
      selector: (row: any) => 0,
      sortable: true,
    },
  ];

  return (
    <Container>
      <Stack
        direction="horizontal"
        className="w-100 justify-content-md-between mt-5"
      >
        <h1>My Coupons</h1>
      </Stack>
      <Stack className="mt-4">
        <DataTable columns={columns} data={tableData} pagination />
      </Stack>
    </Container>
  );
}
