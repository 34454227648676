import React, { useEffect, useMemo, useState } from "react";
import { Container, Form, Image, Button } from "react-bootstrap";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import toast from "react-hot-toast";
import Web3 from "web3";
import Spinner from "react-bootstrap/Spinner";

import Layout from "../layout";
import Header from "../components/Header";
import CouponImg from "../assets/img/coin.png";

import { getCreditPriceApi, setBuyCreditApi } from "../apis/coupon";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../configs/constants";

import { store } from "../store";
import {
  WRITE_CREDIT_CONTRACT,
  WRITE_USDT_CONTRACT,
  READ_USDT_CONTRACT,
} from "../configs/smart_contracts";
import { CREDIT_ADDRESS } from "../configs/addresses";

export default function BuyCredit() {
  const [amount, setAmount] = useState("0");
  const [usd, setUsd] = useState("0");
  const [creditPrice, setCreditPrice] = useState("");
  const [order, setOrder] = useState(true);
  const [loading, setLoading] = useState(false);
  const [usdtBalance, setUsdtBalance] = useState("0");

  const [account] = store.useState("account");
  const [userInfo, setUserInfo] = store.useState("userInfo");

  useEffect(() => {
    (async () => {
      if (account) {
        const _usdtBalance: any = await READ_USDT_CONTRACT.methods
          .balanceOf(account)
          .call();
        setUsdtBalance(Web3.utils.fromWei(_usdtBalance, "ether"));
      }
    })();
  }, [account]);

  useEffect(() => {
    if (order) {
      setUsd((parseFloat(amount) / parseFloat(creditPrice) || 0) as any);
    }
  }, [amount]);

  useEffect(() => {
    if (!order) {
      setAmount((parseFloat(usd) * parseFloat(creditPrice) || 0) as any);
    }
  }, [usd]);

  useEffect(() => {
    (async () => {
      setCreditPrice(await getCreditPriceApi());
    })();
  }, []);

  const buyCouponHandler = async () => {
    if (!(parseFloat(amount) > 0)) {
      return toast("Please input valid amount", customToastStyle);
    }
    if (parseFloat(usdtBalance) < parseFloat(usd)) {
      return toast("Insufficient USDT balance", customToastStyle);
    }
    // setShowModal(true);
    try {
      setLoading(true);
      const writeCreditContract = await WRITE_CREDIT_CONTRACT();
      const writeUsdtContract = await WRITE_USDT_CONTRACT();
      await writeUsdtContract.methods
        .approve(CREDIT_ADDRESS, Web3.utils.toWei(usd.toString(), "ether"))
        .send({ from: account });

      await writeCreditContract.methods
        .mintCredit(Web3.utils.toWei(usd.toString(), "ether"))
        .send({ from: account });

      await setBuyCreditApi(account, amount);

      const credit = (userInfo as any)?.credit || 0;
      setUserInfo({
        ...(userInfo as any),
        credit: parseFloat(credit) + parseFloat(amount),
      });

      toast("Buy credit successfully", customToastSuccessStyle);
      setAmount("0");
      setUsd("0");
    } catch (e: any) {
      toast("Error: " + e.message, customToastStyle);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="page-container">
        <div style={{ zIndex: 2 }} className="w-100">
          <Header />
          <Container style={{ marginTop: 50 }}>
            <Form className="w-50">
              <h2>
                Buy Credits <Image src={CouponImg} width={50} />
              </h2>
              <Form.Group controlId="formFile" className="mt-3">
                {order ? (
                  <>
                    <Form.Label>
                      Credit Amount ($1 = {creditPrice} Credits)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </>
                ) : (
                  <>
                    <Form.Label>USD (available: {usdtBalance} USDT)</Form.Label>
                    <Form.Control
                      type="text"
                      value={usd}
                      onChange={(e) => setUsd(e.target.value)}
                    />
                  </>
                )}
                <div
                  className="d-flex justify-content-center mt-4 "
                  style={{ cursor: "pointer" }}
                  onClick={() => setOrder(!order)}
                >
                  <FaArrowDown />
                  <FaArrowUp />
                </div>
                {order ? (
                  <>
                    <Form.Label>USD (available: {usdtBalance} USDT)</Form.Label>
                    <Form.Control
                      type="text"
                      value={usd}
                      disabled={true}
                      style={{ opacity: 0.9 }}
                    />
                  </>
                ) : (
                  <>
                    <Form.Label>
                      Credit Amount ($1 = {creditPrice} Credits)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      value={amount}
                      disabled={true}
                      style={{ opacity: 0.9 }}
                    />
                  </>
                )}
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-3">
                <Button
                  onClick={buyCouponHandler}
                  disabled={loading}
                  style={{ minWidth: 160 }}
                >
                  {loading ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    <>
                      <Image src={CouponImg} width={30} className="mx-2" />
                      Buy Credits
                    </>
                  )}
                </Button>
              </Form.Group>
            </Form>
          </Container>
        </div>
      </div>
    </Layout>
  );
}
