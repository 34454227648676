import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Web3 from "web3";
import toast from "react-hot-toast";
// @ts-ignore
import { confirm } from "react-bootstrap-confirmation";

import { WRITE_CREDIT_CONTRACT } from "../configs/smart_contracts";

import { store } from "../store";
import { customToastStyle } from "../configs/constants";

import { setPlayTowerMasterApi, getCostTowerMasterApi } from "../apis/coupon";

export default function SelectGame({
  show,
  onHide,
  handleSelect,
  handleNext,
  handleOpen,
  handleGameStart,
}: {
  show: boolean;
  onHide: () => void;
  handleSelect: Function;
  handleNext: () => void;
  handleOpen: () => void;
  handleGameStart: () => void;
}) {
  const SPORTS = ["Football", "NBA Basketball", "Basketball", "Cricket"];

  const [cost, setCost] = useState(0);
  const [selected, setSelected] = useState("");

  const [account] = store.useState("account");
  const [userInfo, setUserInfo] = store.useState("userInfo");

  useEffect(() => {
    (async () => {
      setCost(await getCostTowerMasterApi());
    })();
  }, [account]);

  const nextHandler = async () => {
    if (!selected) {
      return;
    }
    handleNext();
    if (!account) {
      handleGameStart();
      return;
    }
    const res = await confirm(
      `You will pay ${cost} credit to play Tower Master`
    );
    if (res) {
      try {
        const writeCreditContract = await WRITE_CREDIT_CONTRACT();
        await writeCreditContract.methods
          .burnCredit(Web3.utils.toWei(cost, "ether"))
          .send({ from: account });
        const res = await setPlayTowerMasterApi(account);
        if (!res) {
          return toast("Error to play Tower Master", customToastStyle);
        }
        const credit = (userInfo as any)?.credit || 0;
        setUserInfo({
          ...(userInfo as any),
          credit: parseFloat(credit) - parseFloat(cost as any),
        });
        handleGameStart();
      } catch (e) {
        handleOpen();
      }
    } else {
      handleOpen();
    }
  };

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title style={{ fontSize: 18, fontWeight: "bold" }}>
          Sport you would like to make prediction in
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {SPORTS.map((type) => (
            <Form.Check
              type={"radio"}
              label={type === "Football" ? "Futbol" : type}
              id={`default-${type}`}
              name="sport"
              onChange={(e) => {
                setSelected(type);
                handleSelect(type);
              }}
            />
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={nextHandler}>
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
