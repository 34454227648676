import React, { useEffect, useState } from "react";
import { Card, Container, Form, Button, Stack } from "react-bootstrap";
import toast from "react-hot-toast";

import {
  getCostTowerMasterApi,
  setCostTowerMasterApi,
  getTimeLimitTowerMasterApi,
  setTimeLimitTowerMasterApi,
} from "../../apis/coupon";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../../configs/constants";

export default function ManageTowerMaster() {
  const [gamePrice, setGamePrice] = useState("");
  const [dbGamePrice, setDbGamePrice] = useState("");
  const [dbTimeLimit, setDbTimeLimit] = useState("0");
  const [timeLimit, setTimeLimit] = useState("");

  useEffect(() => {
    (async () => {
      setDbGamePrice(await getCostTowerMasterApi());
      setDbTimeLimit(await getTimeLimitTowerMasterApi());
    })();
  }, []);

  const saveGamePriceHandler = async () => {
    if (isNaN(parseFloat(gamePrice))) {
      return toast("Please enter a valid price", customToastStyle);
    }
    const res = await setCostTowerMasterApi(gamePrice);
    if (res) {
      toast("Set cost to play tower master", customToastSuccessStyle);
      setDbGamePrice(gamePrice);
      setGamePrice("");
    } else {
      toast("Error to save cost", customToastStyle);
    }
  };

  const saveTimeLimitHandler = async () => {
    if (isNaN(parseFloat(timeLimit))) {
      return toast("Please enter a valid time", customToastStyle);
    }
    const res = await setTimeLimitTowerMasterApi(timeLimit);
    if (res) {
      toast("Set time limit for tower master", customToastSuccessStyle);
      setDbTimeLimit(timeLimit);
      setTimeLimit("");
    } else {
      toast("Error to save time limit", customToastStyle);
    }
  };

  return (
    <Container style={{ marginTop: 50 }}>
      <h2>Manage Tower Master</h2>
      <Stack direction="vertical">
        <Stack direction="horizontal">
          <Card className="w-50 m-2">
            <Card.Body>
              <Card.Title>Cost To Play TowerMaster</Card.Title>
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>
                  Cost to play Tower Master({dbGamePrice || 0} Credits)
                </Form.Label>
                <Form.Control
                  type="number"
                  value={gamePrice}
                  onChange={(e) => setGamePrice(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-3">
                <Button onClick={saveGamePriceHandler} style={{ minWidth: 80 }}>
                  Save
                </Button>
              </Form.Group>
            </Card.Body>
          </Card>
          <Card className="w-50 m-2">
            <Card.Body>
              <Card.Title>Time limit of TowerMaster</Card.Title>
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>
                  Current time limit {dbTimeLimit} seconds
                </Form.Label>
                <Form.Control
                  type="number"
                  value={timeLimit}
                  onChange={(e) => setTimeLimit(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-3">
                <Button onClick={saveTimeLimitHandler} style={{ minWidth: 80 }}>
                  Save
                </Button>
              </Form.Group>
            </Card.Body>
          </Card>
        </Stack>
      </Stack>
    </Container>
  );
}
