import React, { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";

import Layout from "../layout";
import Header from "../components/Header";
import ManageCoupons from "../components/Dashboard/ManageCoupons";

export default function BuyCoupons() {
  return (
    <Layout>
      <div className="page-container">
        <div style={{ zIndex: 2 }} className="w-100">
          <Header />
          <Container style={{ marginTop: 50 }}>
            <ManageCoupons />
          </Container>
        </div>
      </div>
    </Layout>
  );
}
